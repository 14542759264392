body {
    .p-widget-overlay {
        background-color: $maskBgColor;
    }

    .p-overlaypanel {
        background-color: $panelContentBgColor;
        color: $panelContentTextColor;
        padding: 0;
        border: $overlayContentBorder;
        @include shadow($overlayContainerShadow);

        .p-overlaypanel-content {
            padding: $panelContentPadding;
        }

        .p-overlaypanel-close {
            background-color: $overlayPanelCloseIconBgColor;
            color: $overlayPanelCloseIconColor;
            width: 1.538em;
            height: 1.538em;
            line-height: 1.538em;
            text-align: center;
            position: absolute;
            top: calc(-1* 1.538em / 2);
            right: calc(-1* 1.538em / 2);

            &:hover {
                background-color: $overlayPanelCloseIconHoverBgColor;
                color: $overlayPanelCloseIconHoverColor;
            }
        }

        &:after {
            border-color: rgba($panelContentBgColor, 0);
            border-bottom-color: $panelContentBgColor;
        }

        &:before {
            border-color: rgba($overlayContentBorderColor, 0);
            border-bottom-color: $overlayContentBorderColor;
        }

        &.p-overlaypanel-flipped {
            &:after {
                border-top-color: $panelContentBgColor;
            }

            &:before {
                border-top-color: $overlayContentBorderColor;
            }
        }
    }

    .p-dialog {
        min-width: 400px;
        padding: 0;
        @include shadow($overlayContainerShadow);

        .p-dialog-titlebar {
            border: $panelHeaderBorder;
            background-color: $panelHeaderBgColor;
            color: $panelHeaderTextColor;
            padding: $dialogHeaderPadding;
            font-weight: $panelHeaderFontWeight;
            border-bottom: 0 none;

            .p-dialog-title {
                margin: 0;
                float: none;
            }

            .p-dialog-titlebar-icon {
                color: $panelHeaderIconColor;
                border: 0 none;
                padding: 0;
                margin-left: $inlineSpacing;
                @include transition(color $transitionDuration);

                &:hover {
                    color: $panelHeaderIconHoverColor;
                }
            }
        }

        .p-dialog-content {
            background-color: $panelContentBgColor;
            color: $panelContentTextColor;
            border: $overlayContentBorder;
            padding: $panelContentPadding;
        }

        .p-dialog-footer {
            border: $panelFooterBorder;
            border-top: 0 none;
            background-color: $panelFooterBgColor;
            color: $panelFooterTextColor;
            padding: $panelFooterPadding;
            margin: 0;
            text-align: right;

            button {
                margin: 0 $inlineSpacing 0 0;
                width: auto;
            }
        }

        &.p-confirm-dialog {
            .p-dialog-content {
                padding: $confirmDialogPadding;

                > span {
                    float: none;
                    display: inline-block;
                    vertical-align: middle;
                    line-height: $fontSize;
                    margin: 0;

                    &.p-icon {
                        margin-right: .35em;
                        font-size: $fontSize + 2;
                    }
                }
            }
        }
    }

    .p-sidebar {
        background-color: $panelContentBgColor;
        color: $panelContentTextColor;
        padding: $panelContentPadding;
        border: $overlayContentBorder;
        @include shadow($overlayContainerShadow);
    }

    .p-tooltip {
        .p-tooltip-text {
            background-color: $tooltipBgColor;
            color: $tooltipTextColor;
            padding: $tooltipPadding;
            @include shadow($overlayContainerShadow);
        }

        &.p-tooltip-right {
            .p-tooltip-arrow {
                border-right-color: $tooltipBgColor;
            }
        }

        &.p-tooltip-left {
            .p-tooltip-arrow {
                border-left-color: $tooltipBgColor;
            }
        }

        &.p-tooltip-top {
            .p-tooltip-arrow {
                border-top-color: $tooltipBgColor;
            }
        }

        &.p-tooltip-bottom {
            .p-tooltip-arrow {
                border-bottom-color: $tooltipBgColor;
            }
        }
    }

    .p-lightbox {
        @include shadow($overlayContainerShadow);

        .p-lightbox-caption {
            border: $panelHeaderBorder;
            background-color: $panelHeaderBgColor;
            color: $panelHeaderTextColor;
            padding: $panelHeaderPadding;
            font-weight: $panelHeaderFontWeight;

            .p-lightbox-caption-text {
                color: $panelHeaderTextColor;
                margin: 0;
            }

            .p-lightbox-close {
                padding: 0;
                color: $panelHeaderIconColor;
                @include transition(color $transitionDuration);

                &:hover {
                    color: $panelHeaderIconHoverColor;
                }
            }
        }

        .p-lightbox-content-wrapper {
            overflow: hidden;
            background-color: $panelContentBgColor;
            color: $panelContentTextColor;
            border: 0 none;
            padding: 0;

            .p-lightbox-nav-left, .p-lightbox-nav-right {
                @include transition(all $transitionDuration);
                font-size: $lightBoxNavIconFontSize;
                color: $lightBoxNavIconColor;
                margin-top: -.5em;

                &:hover {
                    @include scale(1.2);
                }
            }

            .p-lightbox-content.p-lightbox-loading ~ a {
                display: none;
            }
        }
    }
}
