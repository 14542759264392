/* Add your customizations of the theme here */

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700');
@import '../variables';

html {
    font-size: 12px;
    font-family: $fontFamily;
    font-weight: 500;
}

.p-component, .p-inputtext {
    font-family: $fontFamily;
    font-weight: 500;
}

body {
    -moz-osx-font-smoothing: auto !important;

    .p-disabled, .p-component:disabled {
        opacity: .75;
    }

    .p-inputtext {
        font-size: 12px;
    }

    .p-widget {
        font-size: 12px;
    }

    .p-paginator {
        font-size: 11px;
    }

    .p-inputgroup {
        &.inline {
            display: inline-flex;
        }

        & > p-dropdown:not(:first-child) > .p-dropdown, & > p-calendar:not(:first-child) .p-inputtext {
            border-left: none;
        }
    }

    .p-datatable {
        border-collapse: collapse;

        td {
            vertical-align: middle;
        }

        th .p-inputgroup {
            font-weight: normal;
        }

        .p-datatable-loading {
            opacity: .4;

            & ~ .p-datatable-wrapper .emptymessage {
                padding: 4em 0 2em;
            }
        }

        .p-datatable-thead {
            background-color: $panelHeaderBgColor;

            & > tr > th {
                padding: .5em;
                white-space: nowrap;
                font-size: 90%;
                background-color: $panelHeaderBgColor;
                text-align: center;

                .pi {
                    font-size: 100%;
                }
            }
        }

        .p-datatable-tfoot > tr {
            & > td {
                background-color: $panelHeaderBgColor;
            }

            &.totals > td {
                font-weight: bold;
            }
        }


        .p-datatable-footer {
            text-align: left;
            font-weight: normal;
        }

        .emptymessage {
            text-align: center;
        }

        &.scrolltable {
            overflow: auto;

            thead {
                th {
                    position: sticky;
                    top: .5px;
                    background-clip: padding-box;
                    box-shadow: 0px 0 0 .5px #dedede;
                }
            }

            tfoot {
                td {
                    position: sticky;
                    bottom: .5px;
                    background-clip: padding-box;
                    box-shadow: 0 0 0 .5px #dedede;

                }
            }
        }
    }

    .p-toast.p-toast-top-right {
        top: 70px;
    }

    .p-card {
        margin-bottom: 1rem;
    }

    .p-dialog {
        .p-dialog-content {
            overflow: auto;
        }

        .p-dialog-footer {
            text-align: center;
        }
    }

    .p-button {
        border-radius: 0;

        .p-button-text {
            font-weight: normal;

            &:hover, &:enabled:hover {
                background: none;
            }

            &:enabled:focus {
                background: none;
                outline: none;
                box-shadow: none;
            }
        }

        .p-button-label {
            white-space: nowrap;
        }
    }

    .p-datatable-thead th, .p-datatable-tfoot td {
        .p-button-info {
            background-color: darken($infoButtonBgColor, 8%);
        }
    }

    .p-button-nonbutton {
        background: none;
        border: 1px solid rgba(0, 0, 0, 0);
        box-shadow: none;
        color: $textColor;
    }

    .p-accordion .p-accordion-header:not(.p-disabled).p-highlight {
        &, &:hover{
            .p-accordion-header-link{
                background-image: linear-gradient(to right, #d00f28, #e8112d);
            }
        }
    }

    p-calendar > .p-calendar {
        width: 100%;
    }

    .p-datepicker {
        table td > span {
            height: 1.9em;
            padding-left: 0;
            padding-right: 0;

            .calendarDay {
                & > *:first-child {
                    display: block;
                    margin: -2px 0 0 0;
                    text-align: center;
                }

                & > *:last-child {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-around;
                }
            }

            &.p-highlight .calendarDay svg circle {
                &.Open {
                    fill: #34A835;
                }

                &.Closed {
                    fill: #ffba01;
                }

                &.Locked {
                    fill: #999999;
                }
            }
        }

        svg {
            flex: none;
            width: 5px;
            height: 5px;
            vertical-align: middle;

            circle {
                &.Open {
                    fill: #34A835;
                }

                &.Closed {
                    fill: #ffba01;
                }

                &.Locked {
                    fill: #999999;
                }
            }
        }

        .legend {
            font-size: 80%;

            & + .legend {
                padding-left: .5em;
            }
        }
    }

    .p-multiselect {
        border-radius: 0;
        width: 100%;
    }

    .p-multiselect-panel {
        .p-checkbox {
            &, & .p-checkbox-box {
                height: 20px;
                width: 20px;
            }
        }
        .p-multiselect-header .p-multiselect-close {
            margin-top: 0;
        }
    }
}
