/* Utils */
.clearfix:after {
    content:" ";
    display:block;
    clear:both;
}

.card {
    border-radius: $borderRadius;
    background: #ffffff;
    padding: 16px;
    box-sizing: border-box;
    margin-bottom: 16px;
    border: 1px solid #dddddd;
    @include shadow(0 1px 3px rgba(0, 0, 0, 0.12));
    
    &.card-w-title {
        padding-bottom: 16px;
    }
    
    &.no-gutter {
        margin-bottom: 0;
    }
    
    h1 {
        font-size: 20px;
        font-weight: 400;
        margin: 24px 0;
        
        &:first-child {
            margin-top: 0px;
        }
    }
    
    h2 {
        font-size: 18px;
        font-weight: 400;
    }
    
    h3 {
        font-size: 16px;
        font-weight: 400;
    }
    
    h4 {
        font-size: 14px;
        font-weight: 400;
    }
}

.nopad {
    padding: 0;
    
    .p-panel-content {
        padding: 0;
    }
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
    transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

@-webkit-keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}

@keyframes fadeOutUp {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0);
  }
}

.fadeInDown {
    -webkit-animation-name: fadeInDown;
    animation-name: fadeInDown;
}

.fadeOutUp {
    -webkit-animation-name: fadeOutUp;
    animation-name: fadeOutUp;
}

.p-g {
    -ms-flex-wrap: wrap;
}

.layout-wrapper {
    .layout-ajax-loader {
        position: absolute;
        right: 15px;
        bottom: 70px;
    
        .layout-ajax-loader-icon {
            color: $primaryColor;
            font-size: 32px;
        }
    }
}