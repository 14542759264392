/* Add your variable customizations of layout here */

$propakRed: #e8112d;
$topbarBgLeftColor: darken($propakRed, 5%);
$topbarBgRightColor: $propakRed;
$lightMenuTitleTextColor: $propakRed;
$lightMenuTitleBorderBottomColor: darken($propakRed, 5%);
$primaryColor: $propakRed;
$sidebarMenuItemActiveRouteTextColor: $propakRed;
$topbarMenuHoverBgColor: darken($propakRed, 10%);
