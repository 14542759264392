$primaryColor:#707277;
$primaryTextColor:#ffffff;

$topbarBgLeftColor:#333333;
$topbarBgRightColor:#5A5D60;
$topbarSidebarButtonColor:#ffffff;
$topbarIconHoverBgColor:#515355;
$topbarMenuHoverBgColor:#707277;
$topbarProfileTextColor:#ffffff;
$activeSubMenuItemTextColor:#7085ba;
$sidebarMenuItemActiveRouteTextColor:#7085ba;
$sidebarMenuItemActiveRouteIconColor:#7085ba;

@import '../../sass/layout/_layout';
