@import '../../../colors';

$fontSize: 12px;

$inputErrorBorderColor: $red-dark;

$buttonBgColor: $blue-moderate;
$buttonBorder: 1px solid $blue-moderate;
$buttonTextColor: $white;
$buttonHoverBgColor: $blue-dark;
$buttonHoverTextColor: $white;
$buttonHoverBorderColor: $blue-dark;
$buttonActiveBgColor: $blue-darker;
$buttonActiveTextColor: $white;
$buttonActiveBorderColor: $blue-darker;
$buttonFocusOutline: 1px solid $primaryColor;
$buttonFocusBorderColor: $primaryColor;

$secondaryButtonBgColor: $primaryColor;
$secondaryButtonBorder: 1px solid $primaryColor;
$secondaryButtonTextColor: $primaryTextColor;
$secondaryButtonHoverBgColor: $primaryDarkerColor;
$secondaryButtonHoverTextColor: $primaryTextColor;
$secondaryButtonHoverBorderColor: $primaryDarkerColor;
$secondaryButtonActiveBgColor: $primaryDarkestColor;
$secondaryButtonActiveTextColor: $primaryTextColor;
$secondaryButtonActiveBorderColor: $primaryDarkestColor;

$infoButtonBgColor: $gray-7;
$infoButtonBorder: 1px solid $gray-7;
$infoButtonTextColor: $gray-2;
$infoButtonHoverBgColor: $gray-6;
$infoButtonHoverTextColor: $gray-1;
$infoButtonHoverBorderColor: $gray-6;
$infoButtonActiveBgColor: $primaryColor;
$infoButtonActiveTextColor: $white;
$infoButtonActiveBorderColor: $primaryColor;

$successButtonBgColor: $green-moderate;
$successButtonTextColor: $white;
$successButtonBorder:1px solid $green-moderate;
$successButtonHoverBgColor: $green-dark;
$successButtonHoverTextColor: $white;
$successButtonHoverBorderColor: $green-dark;
$successButtonActiveBgColor: $green-darker;
$successButtonActiveTextColor: $white;
$successButtonActiveBorderColor: $green-darker;

$warningButtonBgColor: $yellow-moderate;
$warningButtonTextColor: $white;
$warningButtonBorder: 1px solid $yellow-moderate;
$warningButtonHoverBgColor: $yellow-dark;
$warningButtonHoverTextColor: $white;
$warningButtonHoverBorderColor: $yellow-dark;
$warningButtonActiveBgColor: $yellow-darker;
$warningButtonActiveTextColor: $white;
$warningButtonActiveBorderColor: $yellow-darker;

$dangerButtonBgColor: $red-moderate;
$dangerButtonTextColor:$white;
$dangerButtonBorder:1px solid $red-moderate;
$dangerButtonHoverBgColor: $red-dark;
$dangerButtonHoverTextColor:$white;
$dangerButtonHoverBorderColor: $red-dark;
$dangerButtonActiveBgColor: $red-darker;
$dangerButtonActiveTextColor:$white;
$dangerButtonActiveBorderColor: $red-darker;



$toggleButtonBgColor: $gray-6;
$toggleButtonBorder:1px solid $gray-6;
$toggleButtonTextColor: $gray-2;
$toggleButtonIconColor:$gray-3;
$toggleButtonHoverBgColor:$gray-5;
$toggleButtonHoverBorderColor:$gray-5;
$toggleButtonHoverTextColor: $gray-2;
$toggleButtonHoverIconColor: $gray-1;
$toggleButtonActiveBgColor:$blue-moderate;
$toggleButtonActiveBorderColor:$blue-moderate;
$toggleButtonActiveTextColor:$primaryTextColor;
$toggleButtonActiveIconColor:$primaryTextColor;
$toggleButtonActiveHoverBgColor:$blue-moderate;
$toggleButtonActiveHoverBorderColor:$blue-moderate;
$toggleButtonActiveHoverTextColor:$primaryTextColor;
$toggleButtonActiveHoverIconColor:$primaryTextColor;
$toggleButtonFocusOutline:0 none;
$toggleButtonFocusBgColor:$gray-5;
$toggleButtonFocusBorderColor:$gray-4;
$toggleButtonFocusTextColor: $gray-2;
$toggleButtonFocusIconColor: $gray-1;
$toggleButtonActiveFocusBgColor:$blue-moderate;
$toggleButtonActiveFocusBorderColor:$gray-4;
$toggleButtonActiveFocusTextColor:$primaryTextColor;
$toggleButtonActiveFocusIconColor:$primaryTextColor;




$infoMessageBgColor: $blue-light;
$infoMessageBorder: none;
$infoMessageTextColor: $textColor;
$infoMessageIconColor: $textColor;

$successMessageBgColor: $green-light;
$successMessageBorder: none;
$successMessageTextColor: $textColor;
$successMessageIconColor: $textColor;

$warnMessageBgColor: $yellow-light;
$warnMessageBorder: none;
$warnMessageTextColor: $textColor;
$warnMessageIconColor: $textColor;

$errorMessageBgColor: $red-light;
$errorMessageBorder: none;
$errorMessageTextColor: $textColor;
$errorMessageIconColor: $textColor;
