@import "normalize.css/normalize.css";
@import "primeicons/primeicons.css";
@import "primeng/resources/themes/nova/theme.css";
@import "primeng/resources/primeng.min.css";
@import "primeflex/primeflex.scss";

@import "./styles/theme/theme/grey/theme-light";
@import "./styles/theme/layout/css/layout-grey";

@import "./styles/forms";
@import "./styles/pill";
@import "./styles/inset";
