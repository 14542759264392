body {
    .p-widget-content {
        p {
            line-height: 1.5;
            margin: 0;
        }
    }

    .p-panel {
        padding: 0;
        border: 0 none;

        .p-panel-titlebar {
            border: $panelHeaderBorder;
            padding: $panelHeaderPadding;
            background-color: $panelHeaderBgColor;
            color: $panelHeaderTextColor;
            @include border-radius-top($borderRadius);
            @include border-radius-bottom(0);

            .p-panel-title {
                vertical-align: middle;
                font-weight: $panelHeaderFontWeight;
            }

            .p-panel-titlebar-icon {
                margin: 0;
                position: relative;
                font-size: $fontSize;
                color: $panelHeaderIconColor;
                border: 1px solid transparent;
                @include transition($panelHeaderIconTransition);

                &:hover {
                    color: $panelHeaderIconHoverColor;
                }
            }
        }

        .p-panel-content {
            padding: $panelContentPadding;
            border: $panelContentBorder;
            background-color: $panelContentBgColor;
            color: $panelContentTextColor;
            padding: $panelContentPadding;
            border-top: 0 none;
        }

        .p-panel-footer {
            padding: $panelFooterPadding;
            border: $panelFooterBorder;
            background-color: $panelFooterBgColor;
            color: $panelFooterTextColor;
            border-top: 0 none;
            margin: 0;
        }
    }

    .p-fieldset {
        border: $panelContentBorder;
        background-color: $panelContentBgColor;
        color: $panelContentTextColor;

        .p-fieldset-legend {
            a {
                padding: $panelHeaderPadding;
                border: $panelHeaderBorder;
                color: $panelHeaderTextColor;
                background-color: $panelHeaderBgColor;

                .p-fieldset-toggler {
                    float: none;
                    display: inline-block;
                    vertical-align: middle;
                    margin-right: .5em;
                    color: $panelHeaderIconColor;
                }

                .p-fieldset-legend-text{
                    padding: 0;
                }
            }
        }

        &.p-fieldset-toggleable {
            .p-fieldset-legend {
                a {
                    &:hover {
                        background-color: $panelHeaderHoverBgColor;
                        border: $panelHeaderHoverBorder;
                        color: $panelHeaderHoverTextColor;

                        .p-fieldset-toggler {
                            color: $panelHeaderHoverIconColor;
                        }
                    }
                }
            }
        }

        .p-fieldset-content {
            padding: 0;
        }
    }

    .p-accordion {
        .p-accordion-header {
            margin-bottom: $accordionSpacing;

            a {
                padding: $panelHeaderPadding;
                border: $panelHeaderBorder;
                color: $panelHeaderTextColor;
                background-color: $panelHeaderBgColor;
                color: $panelHeaderTextColor;

                .p-accordion-toggle-icon {
                    color: $panelHeaderIconColor;
                }
            }

            &:not(.p-state-active):not(.p-state-disabled):hover {
                a {
                    background-color: $panelHeaderHoverBgColor;
                    border: $panelHeaderHoverBorder;
                    color: $panelHeaderHoverTextColor;

                    .p-accordion-toggle-icon {
                        color: $panelHeaderHoverIconColor;
                    }
                }
            }

            &:not(.p-state-disabled).p-state-active {
                a {
                    background-color: $panelHeaderActiveBgColor;
                    border: $panelHeaderActiveBorder;
                    color: $panelHeaderActiveTextColor;

                    .p-accordion-toggle-icon {
                        color: $panelHeaderActiveIconColor;
                    }
                }

                &:hover {
                    a {
                        border: $panelHeaderActiveHoverBorder;
                        background-color: $panelHeaderActiveHoverBgColor;
                        color: $panelHeaderActiveHoverTextColor;

                        .p-accordion-toggle-icon {
                            color: $panelHeaderActiveHoverIconColor;
                        }
                    }
                }
            }
        }

        .p-accordion-content {
            padding: $panelContentPadding;
            border: $panelContentBorder;
            background-color: $panelContentBgColor;
            color: $panelContentTextColor;
            padding: $panelContentPadding;
        }
    }

    .p-tabview {
        &.p-tabview-top,
        &.p-tabview-bottom,
        &.p-tabview-left,
        &.p-tabview-right {
            border: 0 none;

            .p-tabview-nav {
                padding: 0;
                background: $tabsNavBgColor;

                li {
                    border: $panelHeaderBorder;
                    background-color: $panelHeaderBgColor;

                    a {
                        float: none;
                        display: inline-block;
                        color: $panelHeaderTextColor;
                        padding: $panelHeaderPadding;

                        .p-tabview-left-icon {
                            color: $panelHeaderIconColor;
                            margin-right: $inlineSpacing;
                        }

                        .p-tabview-right-icon {
                            color: $panelHeaderIconColor;
                            margin-left: $inlineSpacing;
                        }
                    }

                    .p-tabview-close {
                        color: $panelHeaderIconColor;
                        margin: 0 $inlineSpacing 0 -1 * $inlineSpacing;
                    }

                    &:not(.p-state-active):not(.p-state-disabled):hover {
                        background-color: $panelHeaderHoverBgColor;
                        border: $panelHeaderHoverBorder;

                        a {
                            color: $panelHeaderHoverTextColor;

                            .p-tabview-left-icon, .p-tabview-right-icon{
                                color: $panelHeaderIconHoverColor;
                            }
                        }

                        .p-tabview-close {
                            color: $panelHeaderHoverTextColor;
                        }
                    }

                    &.p-state-active {
                        background-color: $panelHeaderActiveBgColor;
                        border: $panelHeaderActiveBorder;

                        a {
                            color: $panelHeaderActiveTextColor;

                            .p-tabview-left-icon, .p-tabview-right-icon{
                                color: $panelHeaderActiveIconColor;
                            }
                        }

                        .p-tabview-close {
                            color: $panelHeaderActiveIconColor;
                        }

                        &:hover {
                            border: $panelHeaderActiveHoverBorder;
                            background-color: $panelHeaderActiveHoverBgColor;

                            a {
                                color: $panelHeaderActiveHoverTextColor;

                                .p-tabview-left-icon, .p-tabview-right-icon{
                                    color: $panelHeaderActiveHoverIconColor;
                                }
                            }
                        }
                    }

                    &.p-tabview-selected {
                        a {
                            cursor: pointer;
                        }
                    }
                }
            }
        }

        &.p-tabview-top {
            .p-tabview-nav {
                margin-bottom: -1px;

                li {
                    margin-right: $tabHeaderSpacing;
                }
            }
        }

        &.p-tabview-bottom {
            .p-tabview-nav {
                margin-top: -1px;

                li {
                    margin-right: $tabHeaderSpacing;
                }
            }
        }

        &.p-tabview-left {
            .p-tabview-nav {
                margin-right: -px;

                li {
                    margin-bottom: $tabHeaderSpacing;
                }
            }
        }

        &.p-tabview-right {
            .p-tabview-nav {
                margin-right: -1px;

                li {
                    margin-bottom: $tabHeaderSpacing;
                }
            }
        }

        .p-tabview-panels {
            background-color: $panelContentBgColor;
            padding: $panelContentPadding;
            border: $panelContentBorder;
            color: $panelContentTextColor;

            .p-tabview-panel {
                padding: 0;
            }
        }
    }

    .p-toolbar {
        background-color: $panelHeaderBgColor;
        border: $panelHeaderBorder;
        padding: $panelHeaderPadding;

        button {
            vertical-align: middle;
        }
    }
}
