body {
    .layout-wrapper {
        .layout-main {
            padding-top: 60px;
            @include transition(margin-left $transitionDuration);

            .route-bar {
                @include clearfix();
                min-height: 31px;
                background-color: $routeBarBgColor;
                @include shadow(0 1px 3px rgba(0, 0, 0, 0.12));

                .route-bar-breadcrumb {
                    padding: 6px 12px;
                    display: inline-block;

                    ul {
                        padding-left: 0;
                        margin: 0;

                        li {
                            list-style-type: none;
                            display: inline-block;
                            padding: 0 .25em;
                            margin-bottom: 0;

                            a {
                                font-size: 14px;
                                color: $routeBarMenuItemTextColor;
                            }

                            i {
                                color: $routeBarMenuItemIconColor;
                                font-size: 18px;
                                position: relative;
                                top: 1px;
                            }
                        }
                    }
                }
            }

            .layout-content {
                padding: 14px;
            }
        }
    }

    @media (max-width: 1024px) {
        .layout-wrapper {
            .layout-main {
                margin-top: 60px;
            }
        }
    }
}
