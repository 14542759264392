$primaryColor:#707277;
$primaryDarkerColor:#57595E;
$primaryDarkestColor:#3C3E42;
$primaryTextColor:#ffffff;

$containerHeaderBgColor:#efefef;
$containerHeaderBorder:1px solid #dedede;
$containerHeaderTextColor:#333333;
$containerHeaderIconColor:#6f6f6f;
$containerHeaderIconHoverColor:#323232;

$containerHeaderHoverBgColor:#d1d1d1;
$containerHeaderHoverBorder:1px solid #d1d1d1;
$containerHeaderHoverTextColor:#333333;
$containerHeaderHoverIconColor:#333333;

$containerHeaderActiveBgColor:$primaryColor;
$containerHeaderActiveBorder:1px solid $primaryColor;
$containerHeaderActiveTextColor:$primaryTextColor;
$containerHeaderActiveIconColor:$primaryTextColor;

$containerHeaderActiveHoverBgColor:$primaryDarkerColor;
$containerHeaderActiveHoverHoverBorder:1px solid $primaryDarkerColor;
$containerHeaderActiveHoverrTextColor:$primaryTextColor;
$containerHeaderActiveHoverIconColor:$primaryTextColor;

@import '../../sass/theme/_theme';
