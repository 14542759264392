body {
    .p-paginator {
        background-color: $paginatorBgColor;
        border: $paginatorBorder;
        padding: $paginatorPadding;

        .p-paginator-first,
        .p-paginator-prev,
        .p-paginator-next,
        .p-paginator-last {
            color: $paginatorIconColor;
            height: $paginatorElementHeight;
            min-width: $paginatorElementWidth;
            border: $paginatorElementBorder;
            line-height: $paginatorElementHeight;
            padding: $paginatorElementPadding;
            margin: $paginatorElementMargin;
            vertical-align: top;

            &:not(.p-state-disabled):not(.p-state-active):hover {
                background-color: $paginatorElementHoverBgColor;
                color: $paginatorElementHoverIconColor;
            }
        }

        .p-paginator-first {
            &:before {
                position: relative;
                top: 1px;
            }
        }

        .p-paginator-prev {
            &:before {
                position: relative;
            }
        }

        .p-paginator-next {
            &:before {
                position: relative;
                top: 1px;
            }
        }

        .p-paginator-last {
            &:before {
                position: relative;
                top: 1px;
            }
        }

        .p-paginator-pages {
            vertical-align: top;
            display: inline-block;
            padding: 0;

            .p-paginator-page {
                color: $paginatorIconColor;
                height: $paginatorElementHeight;
                min-width: $paginatorElementWidth;
                border: $paginatorElementBorder;
                line-height: $paginatorElementHeight;
                padding: $paginatorElementPadding;
                margin: $paginatorElementMargin;
                vertical-align: top;

                &.p-highlight {
                    background-color: $highlightBgColor;
                    color: $highlightColorText;
                }

                &:not(.p-state-active):hover {
                    background-color: $paginatorElementHoverBgColor;
                    color: $paginatorElementHoverIconColor;
                }
            }
        }

        .p-dropdown {
            margin-left: $inlineSpacing;
            height: $paginatorElementHeight;
            min-width: auto;
        }
    }

    .p-datatable {
        .p-datatable-caption,
        .p-datatable-footer {
            background-color: $panelHeaderBgColor;
            color: $panelHeaderTextColor;
            border: $panelHeaderBorder;
            padding: $panelHeaderPadding;
            font-weight: $panelHeaderFontWeight;
            text-align: center;
        }

        .p-datatable-caption {
            border-bottom: 0 none;
        }

        .p-datatable-footer {
            border-top: 0 none;
        }

        .p-datatable-thead > tr > th {
            padding: $tableHeaderCellPadding;
            border: $tableHeaderCellBorder;
            font-weight: $tableHeaderCellFontWeight;
            color: $tableHeaderCellTextColor;
            background-color: $tableHeaderCellBgColor;
        }

        .p-datatable-tbody > tr > td {
            padding: $tableBodyCellPadding;
        }

        .p-datatable-tfoot > tr > td {
            padding: $tableFooterCellPadding;
            border: $tableFooterCellBorder;
            font-weight: $tableFooterCellFontWeight;
            color: $tableFooterCellTextColor;
            background-color: $tableFooterCellBgColor;
        }

        .p-sortable-column {
            .p-sortable-column-icon {
                color: $tableHeaderCellTextColor;
            }

            &:not(.p-state-highlight):hover {
                background-color: $tableHeaderCellHoverBgColor;
                color: $tableHeaderCellHoverTextColor;

                .p-sortable-column-icon {
                    color: $tableHeaderCellHoverTextColor;
                }
            }

            &.p-state-highlight {
                background-color: $highlightBgColor;
                color: $highlightColorText;

                .p-sortable-column-icon {
                    color: $highlightColorText;
                }
            }
        }

        .p-datatable-tbody {
            > tr {
                background: $tableBodyRowBgColor;
                color: $tableBodyRowTextColor;

                > td {
                    background: inherit;
                    border: $tableBodyCellBorder;
                }

                > td.canceled {
                    background: #ffe399;
                }

                &.p-state-highlight {
                    background-color: $highlightBgColor;
                    color: $highlightColorText;
                }

                &.p-contextmenu-selected {
                    background-color: $highlightBgColor;
                    color: $highlightColorText;
                }
            }

            > tr:nth-child(even) {
                background-color: $tableBodyRowEvenBgColor;

                &.p-state-highlight {
                    background-color: $highlightBgColor;
                    color: $highlightColorText;
                }

                &.p-contextmenu-selected {
                    background-color: $highlightBgColor;
                    color: $highlightColorText;
                }
            }
            
            > tr.searchResults {
                background-color: $green-lighter;
            }
        }

        &.p-datatable-hoverable-rows {
            .p-datatable-tbody > tr.p-selectable-row:not(.p-state-highlight):not(.p-contextmenu-selected):hover {
                cursor: pointer;
                background-color: $tableBodyRowHoverBgColor;
                color: $tableBodyRowHoverTextColor;
            }
        }

        .p-column-resizer-helper {
            background-color: $tableResizerHelperBgColor;
        }
    }

    @media screen and (max-width: 40em) {
        .p-datatable {
            &.p-datatable-responsive {
                .p-paginator-top {
                    border-bottom: $paginatorBorder;
                }

                .p-paginator-bottom {
                    border-top: $paginatorBorder;
                }

                .p-datatable-tbody > tr > td {
                    border: 0 none;
                }
            }
        }
    }

    .p-datagrid {
        .p-datagrid-header {
            background-color: $panelHeaderBgColor;
            color: $panelHeaderTextColor;
            border: $panelHeaderBorder;
            padding: $panelHeaderPadding;
            font-weight: $panelHeaderFontWeight;
            border-bottom: 0 none;
        }

        .p-datagrid-content {
            padding: $panelContentPadding;
            border: $panelContentBorder;
            background-color: $panelContentBgColor;
            color: $panelContentTextColor;
            padding: $panelContentPadding;
        }

        .p-datagrid-footer {
            background-color: $panelFooterBgColor;
            color: $panelFooterTextColor;
            border: $panelFooterBorder;
            padding: $panelFooterPadding;
            border-top: 0 none;
        }
    }

    .p-datalist {
        .p-datalist-header {
            background-color: $panelHeaderBgColor;
            color: $panelHeaderTextColor;
            border: $panelHeaderBorder;
            padding: $panelHeaderPadding;
            font-weight: $panelHeaderFontWeight;
            border-bottom: 0 none;
        }

        .p-datalist-content {
            padding: $panelContentPadding;
            border: $panelContentBorder;
            background-color: $panelContentBgColor;
            color: $panelContentTextColor;
            padding: $panelContentPadding;
        }

        .p-datalist-footer {
            background-color: $panelFooterBgColor;
            color: $panelFooterTextColor;
            border: $panelFooterBorder;
            padding: $panelFooterPadding;
            border-top: 0 none;
        }
    }

    .p-datascroller {
        .p-datascroller-header {
            background-color: $panelHeaderBgColor;
            color: $panelHeaderTextColor;
            border: $panelHeaderBorder;
            padding: $panelHeaderPadding;
            font-weight: $panelHeaderFontWeight;
            border-bottom: 0 none;
        }

        .p-datascroller-content {
            padding: $panelContentPadding;
            border: $panelContentBorder;
            background-color: $panelContentBgColor;
            color: $panelContentTextColor;
            padding: $panelContentPadding;
        }

        .p-datascroller-footer {
            background-color: $panelFooterBgColor;
            color: $panelFooterTextColor;
            border: $panelFooterBorder;
            padding: $panelFooterPadding;
            border-top: 0 none;
        }
    }

    .p-dataview {
        .p-dataview-header {
            background-color: $panelHeaderBgColor;
            color: $panelHeaderTextColor;
            border: $panelHeaderBorder;
            padding: $panelHeaderPadding;
            font-weight: $panelHeaderFontWeight;
            border-bottom: 0 none;
        }

        .p-dataview-content {
            padding: $panelContentPadding;
            border: $panelContentBorder;
            background-color: $panelContentBgColor;
            color: $panelContentTextColor;
        }

        .p-dataview-footer {
            background-color: $panelFooterBgColor;
            color: $panelFooterTextColor;
            border: $panelFooterBorder;
            padding: $panelFooterPadding;
            border-top: 0 none;
        }
    }

    .fc {
        .fc-button {
            color: $buttonTextColor;
            background-color: $buttonBgColor;
            font-size: $fontSize;
            font-family: $fontFamily;
            height: 2.25em;
            padding: 0 1em;
            border: $buttonBorder;

            &:enabled:not(:focus):not(.fc-button-active):hover {
                background-color: $buttonHoverBgColor;
                color: $buttonHoverTextColor;
                border-color: $buttonHoverBorderColor;
            }

            &:enabled:not(.fc-button-active):focus {
                outline: $buttonFocusOutline;
                outline-offset: $buttonFocusOutlineOffset;
                background-color: lighten($buttonBgColor, 5%);
                box-shadow: none;
            }

            &.fc-button-active {
                background-color: $buttonActiveBgColor;
                color: $buttonActiveTextColor;
                border-color: $buttonActiveBorderColor;

                &:focus {
                    box-shadow: none;
                }
            }
        }

        .fc-head {
            .fc-row {
                background-color: $panelHeaderBgColor;
                color: $panelHeaderTextColor;

                th {
                    border: $panelHeaderBorder;
                    padding: $panelHeaderPadding;
                }
            }
        }

        td.fc-today {
            background-color: $primaryColor;
            color: $primaryTextColor;
        }

        .fc-toolbar {
            .p-state-active {
                background-color: $primaryColor;
                color: $primaryTextColor;
            }
        }

        .fc-event {
            background-color: darken($primaryColor,5%);
            color: $primaryTextColor;
        }

        table {
            box-sizing: border-box;
        }

        div.p-widget-content {
            padding-left: 0px;
            padding-right: 0px;
        }
    }

    .p-fluid {
        .fc {
            .fc-toolbar {
                .p-button {
                    width: auto;
                }
            }
        }
    }

    .p-picklist {
        .p-picklist-buttons {
            button {
                font-size: $fontSize + 2;
            }
        }

        .p-picklist-caption {
            background-color: $panelHeaderBgColor;
            color: $panelHeaderTextColor;
            border: $panelHeaderBorder;
            padding: $panelHeaderPadding;
            font-weight: $panelHeaderFontWeight;
        }

        .p-picklist-filter-container {
            padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
            border: $panelHeaderBorder;
            color: $inputListHeaderTextColor;
            background-color: $inputListHeaderBgColor;
            margin: $inputListHeaderMargin;
            border-bottom: 0 none;
            border-top: 0 none;

            input {
                width: 100%;
                padding-right: 2em;
            }

            .pi {
                top: 50%;
                margin-top: -.5em;
                left: auto;
                right: $inputListHeaderPaddingRight + .5em;
                color: $inputListHeaderSearchIconColor;
            }
        }

        .p-picklist-buttons {
            padding: $panelContentPadding;
        }

        .p-picklist-list {
            border: $panelContentBorder;
            background-color: $panelContentBgColor;
            color: $panelContentTextColor;
            padding: $inputListPadding;

            .p-picklist-item {
                padding: $inputListItemPadding;
                margin: $inputListItemMargin;
                border: $inputListItemBorder;
                color: $inputListItemTextColor;
                background-color: $inputListItemBgColor;

                &:not(.p-state-highlight):hover {
                    background-color: $inputListItemHoverBgColor;
                    color: $inputListItemHoverTextColor;
                }

                &.p-state-highlight {
                    background-color: $inputListItemHighlightBgColor;
                    color: $inputListItemHighlightTextColor;
                }
            }
        }
    }

    @media (max-width: 40em) {
        .p-picklist.p-picklist-responsive {
            .p-picklist-buttons {
                padding: $panelContentPadding;
            }
        }
    }

    .p-orderlist {
        .p-orderlist-controls {
            padding: $panelContentPadding;

            button {
                font-size: $fontSize + 2;
            }
        }

        .p-orderlist-caption {
            background-color: $panelHeaderBgColor;
            color: $panelHeaderTextColor;
            border: $panelHeaderBorder;
            padding: $panelHeaderPadding;
            font-weight: $panelHeaderFontWeight;
        }

        .p-orderlist-filter-container {
            padding: $inputListHeaderPaddingTop $inputListHeaderPaddingRight $inputListHeaderPaddingBottom $inputListHeaderPaddingLeft;
            border: $panelHeaderBorder;
            color: $inputListHeaderTextColor;
            background-color: $inputListHeaderBgColor;
            margin: $inputListHeaderMargin;
            border-bottom: 0 none;
            border-top: 0 none;

            input {
                width: 100%;
                padding-right: 2em;
            }

            .pi {
                top: 50%;
                margin-top: -.5em;
                left: auto;
                right: $inputListHeaderPaddingRight + .5em;
                color: $inputListHeaderSearchIconColor;
            }
        }

        .p-orderlist-list {
            border: $panelContentBorder;
            background-color: $panelContentBgColor;
            color: $panelContentTextColor;
            padding: $inputListPadding;

            .p-orderlist-item {
                padding: $inputListItemPadding;
                margin: $inputListItemMargin;
                border: $inputListItemBorder;
                color: $inputListItemTextColor;
                background-color: $inputListItemBgColor;

                &:not(.p-state-highlight):hover {
                    background-color: $inputListItemHoverBgColor;
                    color: $inputListItemHoverTextColor;
                }

                &.p-state-highlight {
                    background-color: $inputListItemHighlightBgColor;
                    color: $inputListItemHighlightTextColor;
                }
            }
        }
    }

    .p-tree {
        border: $panelContentBorder;
        background-color: $panelContentBgColor;
        color: $panelContentTextColor;
        padding: $panelContentPadding;

        .p-tree-container {
            padding: 0;
            margin: 0;

            .p-treenode {
                padding: $treeNodePadding;

                .p-treenode-content {
                    padding: 0;

                    .p-tree-toggler {
                        vertical-align: middle;
                        display: inline-block;
                        float: none;
                        margin: 0 $treeNodeContentSpacing 0 0;
                        color: $dataIconColor;
                        font-size: $fontSize + 2;
                    }

                    .p-treenode-icon {
                        vertical-align: middle;
                        display: inline-block;
                        margin: 0 $treeNodeContentSpacing 0 0;
                        color: $dataIconColor;
                        font-size: $fontSize + 2;
                    }

                    .p-treenode-label {
                        margin: 0;
                        vertical-align: middle;
                        display: inline-block;
                        padding: $treeNodeLabelPadding;

                        &.p-state-highlight {
                            background-color: $highlightBgColor;
                            color: $highlightColorText;
                        }
                    }

                    .p-chkbox {
                        margin: 0 $inlineSpacing 0 0;

                        .p-chkbox-icon {
                            margin: 1px 0 0 0;
                        }
                    }

                    &.p-treenode-selectable {
                        .p-treenode-label:not(.p-state-highlight):hover {
                            background-color: $inputListItemHoverBgColor;
                            color: $inputListItemHoverTextColor;
                        }
                    }

                    > span {
                        line-height: inherit;
                    }

                    &.p-treenode-dragover {
                        background-color: $inputListItemHoverBgColor;
                        color: $inputListItemHoverTextColor;
                    }
                }
            }

            .p-treenode-droppoint {
                &.p-treenode-droppoint-active {
                    background-color: $highlightBgColor;
                }
            }
        }

        &.p-tree-horizontal {
            padding-left: 0;
            padding-right: 0;

            .p-treenode {

                .p-treenode-content {
                    border: $panelContentBorder;
                    background-color: $panelContentBgColor;
                    color: $panelContentTextColor;
                    padding: $panelContentPadding;

                    &.p-state-highlight {
                        background-color: $highlightBgColor;
                        color: $highlightColorText;
                    }

                    .p-chkbox {
                        .p-icon {
                            color: $primaryColor;
                        }
                    }

                    .p-treenode-label:not(.p-state-highlight):hover {
                        background-color: inherit;
                        color: inherit;
                    }

                    &.p-treenode-selectable:not(.p-state-highlight):hover {
                        background-color: $inputListItemHoverBgColor;
                        color: $inputListItemHoverTextColor;
                    }
                }
            }
        }
    }

    .p-organizationchart {
        .p-organizationchart-node-content {
            &.p-organizationchart-selectable-node:not(.p-state-highlight):hover {
                background-color: $inputListItemHoverBgColor;
                color: $inputListItemHoverTextColor;
            }

            &.p-state-highlight {
                background-color: $highlightBgColor;
                color: $highlightColorText;

                .p-node-toggler {
                    i {
                        color: darken($highlightBgColor, 25%);
                    }
                }
            }
        }

        .p-organizationchart-line-down {
            background-color: $organizationChartConnectorColor;
        }

        .p-organizationchart-line-left {
            border-right: $panelContentBorder;
            border-color: $organizationChartConnectorColor;
        }

        .p-organizationchart-line-top {
            border-top: $panelContentBorder;
            border-color: $organizationChartConnectorColor;
        }

        .p-organizationchart-node-content {
            border: $panelContentBorder;
            background-color: $panelContentBgColor;
            color: $panelContentTextColor;
            padding: $panelContentPadding;
        }

        .p-organizationchart-node-content .p-node-toggler {
            bottom: -.7em;
            margin-left: -.46em;
            color: $dataIconColor;
        }
    }

    .p-carousel {
        .p-carousel-content {
            .p-carousel-prev,
            .p-carousel-next {
                background-color: $carouselNavButtonsBgColor;
                border: $carouselNavButtonsBorder;
                @include border-radius($carouselNavButtonsBorderRadius);
                margin: $carouselNavButtonsMargin;
                color: $carouselNavButtonsColor;
                width: auto;
                min-width: 2.5em;
                @include transition($carouselNavButtonsTransition);

                &:not(.p-state-disabled) {
                    &:hover {
                        background-color: $carouselNavButtonsHoverBgColor;
                        color: $carouselNavButtonsHoverColor;
                        border-color: $carouselNavButtonsHoverBorderColor;
                    }
                }
            }
        }

        .p-carousel-dots-container {
            .p-carousel-dot-item {
                > .p-button {
                    border-color: transparent;
                    background-color: transparent;
                }

                .p-carousel-dot-icon {
                    width: $carouselDotIconWidth;
                    height: $carouselDotIconHeight;
                    background-color: $carouselDotIconBgColor;
                    margin: $carouselDotIconMargin;

                    &::before {
                        content: ' ';
                    }
                }

                &.p-state-highlight {
                    .p-carousel-dot-icon {
                        background-color: $carouselActiveDotIconBgColor;
                    }
                }
            }
        }
    }

    .p-treetable {
        .p-treetable-caption,
        .p-treetable-summary {
            background-color: $panelHeaderBgColor;
            color: $panelHeaderTextColor;
            border: $panelHeaderBorder;
            padding: $panelHeaderPadding;
            font-weight: $panelHeaderFontWeight;
            text-align: center;
        }

        .p-treetable-caption {
            border-bottom: 0 none;
        }

        .p-treetable-summary {
            border-top: 0 none;
        }

        .p-treetable-thead > tr > th {
            padding: $tableHeaderCellPadding;
            border: $tableHeaderCellBorder;
            font-weight: $tableHeaderCellFontWeight;
            color: $tableHeaderCellTextColor;
            background-color: $tableHeaderCellBgColor;
        }

        .p-treetable-tbody > tr > td {
            padding: $tableBodyCellPadding;
        }

        .p-treetable-tfoot > tr > td {
            padding: $tableFooterCellPadding;
            border: $tableFooterCellBorder;
            font-weight: $tableFooterCellFontWeight;
            color: $tableFooterCellTextColor;
            background-color: $tableFooterCellBgColor;
        }

        .p-sortable-column {
            .p-sortable-column-icon {
                color: $tableHeaderCellTextColor;
            }

            &:not(.p-state-highlight):hover {
                background-color: $tableHeaderCellHoverBgColor;
                color: $tableHeaderCellHoverTextColor;

                .p-sortable-column-icon {
                    color: $tableHeaderCellHoverTextColor;
                }
            }

            &.p-state-highlight {
                background-color: $highlightBgColor;
                color: $highlightColorText;

                .p-sortable-column-icon {
                    color: $highlightColorText;
                }
            }
        }

        .p-treetable-tbody {
            > tr {
                background: $tableBodyRowBgColor;
                color: $tableBodyRowTextColor;

                > td {
                    background: inherit;
                    border: $tableBodyCellBorder;

                    .p-treetable-toggler {
                        color: $dataIconColor;
                        vertical-align: middle;
                        line-height: 1.5;
                        font-size: $fontSize + 2;
                    }

                    .p-treetable-chkbox {
                        vertical-align: middle;
                        margin-right: $inlineSpacing;
                    }
                }

                &.p-state-highlight {
                    background-color: $highlightBgColor;
                    color: $highlightColorText;

                    > td {
                        background: inherit;
                        border: $tableBodyCellBorder;

                        .p-treetable-toggler {
                            color: $highlightColorText;
                        }
                    }
                }

                &.p-contextmenu-selected {
                    background-color: $highlightBgColor;
                    color: $highlightColorText;

                    > td {
                        background: inherit;
                        border: $tableBodyCellBorder;

                        .p-treetable-toggler {
                            color: $highlightColorText;
                        }
                    }
                }
            }
        }

        &.p-treetable-hoverable-rows {
            .p-treetable-tbody > tr:not(.p-state-highlight):not(.p-contextmenu-selected):hover {
                cursor: pointer;
                background-color: $tableBodyRowHoverBgColor;
                color: $tableBodyRowHoverTextColor;
            }
        }

        .p-column-resizer-helper {
            background-color: $tableResizerHelperBgColor;
        }
    }

    .p-virtualscroller {
         .p-virtualscroller-header {
             background-color: $panelHeaderBgColor;
             color: $panelHeaderTextColor;
             border: $panelHeaderBorder;
             padding: $panelHeaderPadding;
             font-weight: $panelHeaderFontWeight;
             border-bottom: 0 none;
         }

         .p-virtualscroller-content {
             border: $panelContentBorder;
             background-color: $panelContentBgColor;
             color: $panelContentTextColor;
             .p-virtualscroller-list {
                 li {
                     border-bottom: $panelHeaderBorder;
                 }
             }
         }

         .p-virtualscroller-footer {
             background-color: $panelFooterBgColor;
             color: $panelFooterTextColor;
             border: $panelFooterBorder;
             padding: $panelFooterPadding;
             border-top: 0 none;
         }
    }
}
