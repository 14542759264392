/* Add your customizations of layout here */

#topbar-profile-username {
    float: right;
    color: $topbarSidebarButtonColor;
    padding: 21px 20px 19px 20px;
    font-size: 14px;
    line-height: 20px;

    // &:hover {
    //     background-color: $topbarMenuHoverBgColor;
    // }
}

.layout-wrapper {
    .layout-topbar {
        &.test {
            // background-color: #b80d85;
            // background-image: linear-gradient(to right, #a50c77, #b80d85);
            // background-color: #56B6D2;
            // background-image: linear-gradient(to right, #41A7C4, #56B6D2);
            background-color: #AD69BE;
            background-image: linear-gradient(to right, #9750A9, #AD69BE);
        }

        .logo-container {
            position: absolute;
            top: 0 !important;
            left: 50vw !important;
            width: 400px;
            margin-left: -200px;
            padding-top: 5px;
            padding-bottom: 5px;
            color: white;
            font-size: 1.5em;
            font-weight: bold;

            img {
                height: 100%;
            }
        }
    }

    .layout-sidebar {
        height: 100%;
        height: calc(100vh - 60px);


    }
}

@media (max-width: 1024px) {
    #topbar-profile-username {
        display: none;
    }

    .layout-wrapper {
        .layout-topbar {
            height: 60px;

            .logo-container {
                top: 20px;
                left: 0;
            }

            #topbar-profile-menu-button {
                top: 0;
            }
        }
    }
}

.layout-wrapper {
    .layout-main {
        position: relative;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        background-color: #f8f8f8;

        .layout-content {
            flex-grow: 1;
        }

        .loading-overlay {
            z-index: 10;
            position: fixed;
            background-color: rgba(0, 0, 0, .4);
            color: rgba(0, 0, 0, 0.3);
            height: calc(100vh - 60px);
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            opacity: 0;
            transform: scale(0);
            transition: opacity .2s ease-in-out 0s, transform .01s linear .2s;

            &.shown {
                opacity: 1;
                transform: scale(1);
                transition: opacity .2s ease-in-out .01s, transform .01s linear 0s;
            }

            & > i {
                font-size: 200px;
                // keep centered (ish) when menu opens & collapses (see rule below)
                margin-left: -112px;
                transition: margin-left .3s ease-in-out 0s;
            }
        }
    }

    &.layout-menu-static-inactive .layout-main .loading-overlay i {
        margin-left: 0;
    }
}

.centered {
    text-align: center !important;
}

.p-datatable tr > td.centeredCheckboxInTable {
    text-align: center;
}

// hack to fix input line-height issues
button, input, optgroup, select, textarea, .p-dropdown, .p-button, .p-inputtext {
    line-height: initial !important;
}

p-dropdown > .p-dropdown {
    height: 100%;
}

p, ul, li {
    margin-top: 0;
    margin-bottom: 1.5em;

    &:last-child {
        margin-bottom: 0;
    }
}

ul {
    padding-left: 0;
    list-style: none;

    &.bulleted {
        padding-left: 20px;
        list-style: disc;
    }
}

.text-deemphasized {
    color: $textSecondaryColor;
}

body a {
    &:link, &:visited, &:active {
        text-decoration: underline;
    }
}

.p-card {
    &.collapsed, &.expanded {
        .p-card-header {
            cursor: pointer;
        }
    }
    &.collapsed > .p-card-body {
        display: none;
    }
    &.fit {
        &, & > .p-card-body, & > .p-card-body > .p-card-content {
            display: inline-block;
        }
    }

    .p-card-title {
        margin-bottom: 1rem;
    }

}

.p-card-header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    & + .p-card-body {
        padding-top: 0;

        & > .p-card-content {
            padding-top: 0;
        }
    }
}

.p-card-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;

    & > * {
        flex-grow: 0;
        flex-shrink: 0;
    }

    & > .p-card-content {
        flex-grow: 1;
        padding: 0;

        & > p {
            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.p-grid > [class*="p-col"]:not(:only-child) > p-card {
    display: block;
    height: 100%;

    .p-card {
        height: calc(100% - 1rem); // just 100% cuts 1rem bottom margin for some reason
    }
}

.p-card-footer {
    text-align: center;
}

.p-card-footer button {
    margin: 5px;
}

.p-toast-message > .p-toast-message-content {
    display: grid;
    grid-template-columns: auto 25px;
    grid-template-areas: "header closebtn" "detail detail";

    & > h3 {
        grid-area: header;
        margin: 6px 0 0 0;
    }

    & > p {
        grid-area: detail;
    }

    & > button {
        grid-area: closebtn;
    }
}
