@import "./theme/sass/overrides/_theme_variables";

.p-inputgroup .p-inputtext, .p-fluid .p-inputgroup .p-inputtext, .p-inputgroup .p-inputwrapper, .p-inputgroup .p-inputwrapper > .p-component {
	width: auto;
}

body {
	form {
		.form-group, .field-group {
			& > input, & > p-calendar, & > p-dropdown, & > .p-inputgroup:not(.inline) {
				width: 100%;
			}

			& > p-dropdown > .p-dropdown, & > p-calendar > .p-calendar, & > p-autocomplete > .p-autocomplete {
				width: 100%;
			}

			&.no-label {
				display: flex;
				flex-direction: column;
				justify-content: end;
			}

			.p-calendar, .p-dropdown, .p-autocomplete {
				display: flex;
			}

			padding-bottom: 1.1em;
		}

		:not(.p-inputgroup.inline) > label {
			display: block;
			padding-bottom: .5em;
			font-size: 90%;
		}

		h3 {
			border-bottom: 1px solid #c8c8c8;
		}

		.p-inputgroup {
			& > * {
				flex-grow: 0;
				flex-shrink: 1;
			}
			& > input, & > p-calendar, & > p-dropdown, & > p-autocomplete {
				flex-grow: 1;
			}
		}
	}

	.form-group, .field-group, td, th {

		label, p-selectbutton, p-dropdown, input[type="text"] {
			display: block;
			width: 100%;
		}

		p-checkbox {
			width: 100%;
		}

		.p-inputgroup {
			label, p-selectbutton, p-dropdown, p-checkbox, input[type="text"] {
				width: auto;
			}
		}

		.p-chips {
			width: 100%;
		}

		.p-chips-multiple-container {
			width: 100%;
			flex-wrap: wrap;
			align-content: space-between;
			padding-bottom: 0;
		}

		.canceled {
			background: orange;
		}

		p-checkbox > label.p-checkbox-label {
			display: inline;
			width: auto;
		}

	}

	p-celleditor {
		display: block;
		width: 100%;

		input {
			width: 100%;
		}
	}

	.p-inputgroup button {
		white-space: nowrap;
	}

	.p-inputtext, .p-dropdown {
		min-width: 0;
		border-radius: 0;
		max-width: 100%;
	}

	td {
		.p-inputtext, .p-dropdown {
			width: 100%;
		}
	}

	.p-inputtextarea {
		width: 100%;
	}

	label.required::after, span.required {
		color: $propakRed;
		font-size: 80%;
		vertical-align: top;
	}
	label.required::after {
		content: " *";
	}

	.p-calendar, .p-dropdown, .p-autocomplete {
		display: inline-flex;
		flex-direction: row;

		.p-inputtext {
			flex-grow: 1;
			flex-shrink: 1;
		}

		.p-calendar-button, .p-dropdown-trigger, .p-autocomplete-dropdown {
			flex-grow: 0;
			flex-shrink: 0;
		}
	}

	.p-dropdown .p-dropdown-label {
		padding: 0.429em;
	}

	.p-calendar .p-datepicker-calendar tr:hover {
		background-color: #f8f8f8;
	}

	p-dropdown.ng-invalid:not(.ng-untouched) .p-dropdown,
	p-calendar.ng-invalid:not(.ng-untouched) .p-inputtext,
	p-autocomplete.ng-invalid:not(.ng-untouched) .p-inputtext {
		border-color: $inputErrorBorderColor;
		width: 100%;
	}

	p-table .p-dropdown {
		width:100% !important;
	}

	.p-grid .p-dropdown {
		width:100% !important;
	}

	.form-buttons {
		text-align: center;
		.p-button {
			font-size: 14px;
		}
	}

	body .p-tabmenu .p-tabmenu-nav {
		margin-bottom: 1.3em;
		background-color: $scrollPanelTrackBgColor;
	}

	body .p-tabmenu .p-tabmenu-nav .p-tabmenuitem.p-state-active {
		background-color: $blue-dark ;
		border: 1px solid $blue-dark;
	}

	.p-messages-detail {
		white-space: pre-line;
	}

	.p-toast .p-toast-message {
		.p-toast-message-content {
			white-space: pre-line;
		}
	}

	.p-table td > input[type="text"], input[type="number"], .p-table td > .p-inputgroup {
		width: 100%;
	}

	:not(.p-calendar)>.p-inputtext[readonly] {
		border: none !important;
		box-shadow: none !important;
		&:focus {
			border-color: transparent !important;
		}
	}

	.p-inputtext.ng-dirty {
		border-color: #c4c4c4;
	}

	confirmation-button {
		display: inline-block;
	}
}
