body {
    .p-messages {
        padding: $messagesPadding;
        margin: $messagesMargin;

        &.p-messages-info {
            background-color: $infoMessageBgColor;
            border: $infoMessageBorder;
            color: $infoMessageTextColor;

            .p-messages-icon {
                color: $infoMessageIconColor;
            }

            .p-messages-close {
                color:  $infoMessageIconColor;
            }
        }

        &.p-messages-success {
            background-color: $successMessageBgColor;
            border: $successMessageBorder;
            color: $successMessageTextColor;

            .p-messages-icon {
                color: $successMessageIconColor;
            }

            .p-messages-close {
                color:  $successMessageIconColor;
            }
        }

        &.p-messages-warn {
            background-color: $warnMessageBgColor;
            border: $warnMessageBorder;
            color: $warnMessageTextColor;

            .p-messages-icon {
                color: $warnMessageIconColor;
            }

            .p-messages-close {
                color:  $warnMessageIconColor;
            }
        }

        &.p-messages-error {
            background-color: $errorMessageBgColor;
            border: $errorMessageBorder;
            color: $errorMessageTextColor;

            .p-messages-icon {
                color: $errorMessageIconColor;
            }

            .p-messages-close {
                color:  $errorMessageIconColor;
            }
        }

        .p-messages-close {
            font-size: $messageCloseIconFontSize;
            top: .25em;
            right: .5em;
        }

        .p-messages-icon {
            font-size: $messagesIconFontSize;
        }
    }

    .p-message {
        padding: .35em;

        &.p-message-info {
            background-color: $infoMessageBgColor;
            border: $infoMessageBorder;
            color: $infoMessageTextColor;
        }

        &.p-message-warn {
            background-color: $warnMessageBgColor;
            border: $warnMessageBorder;
            color: $warnMessageTextColor;
        }

        &.p-message-error {
            background-color: $errorMessageBgColor;
            border: $errorMessageBorder;
            color: $errorMessageTextColor;
        }

        &.p-message-success {
            background-color: $successMessageBgColor;
            border: $successMessageBorder;
            color: $successMessageTextColor;
        }

        .p-message-close {
            text-decoration: none;
            color: #fff;
            right: .25em;
        }
    }

    .p-growl {
        top: $growlTopLocation;

        .p-growl-item-container {
            margin: $growlMargin;
            @include opacity($growlOpacity);
            @include shadow($growlShadow);

            .p-growl-item {
                padding: $growlPadding;

                .p-growl-message {
                    margin: $growlMessageTextMargin;
                }

                .p-growl-image {
                    font-size: $growlIconFontSize;
                }
            }

            &.p-growl-message-info {
                background-color: $infoMessageBgColor;
                border: $infoMessageBorder;
                color: $infoMessageTextColor;

                .p-growl-image {
                    color: $infoMessageIconColor;
                }
            }

            &.p-growl-message-success {
                background-color: $successMessageBgColor;
                border: $successMessageBorder;
                color: $successMessageTextColor;

                .p-growl-image {
                    color: $successMessageIconColor;
                }
            }

            &.p-growl-message-warn {
                background-color: $warnMessageBgColor;
                border: $warnMessageBorder;
                color: $warnMessageTextColor;

                .p-growl-image {
                    color: $warnMessageIconColor;
                }
            }

            &.p-growl-message-error {
                background-color: $errorMessageBgColor;
                border: $errorMessageBorder;
                color: $errorMessageTextColor;

                .p-growl-image {
                    color: $errorMessageIconColor;
                }
            }

        }
    }

    .p-toast {
         .p-toast-message {
             @include shadow($growlShadow);

             &.p-toast-message-info {
                 background-color: $infoMessageBgColor;
                 border: $infoMessageBorder;
                 color: $infoMessageTextColor;

                 .p-toast-close-icon {
                     color: $infoMessageTextColor;
                 }

                 .p-toast-icon {
                     color: darken($infoMessageBgColor, 30%);
                 }
             }

             &.p-toast-message-success {
                 background-color: $successMessageBgColor;
                 border: $successMessageBorder;
                 color: $successMessageTextColor;

                 .p-toast-close-icon {
                     color: $successMessageTextColor;
                 }

                 .p-toast-icon {
                     color: darken($successMessageBgColor, 30%);
                 }
             }

             &.p-toast-message-warn {
                 background-color: $warnMessageBgColor;
                 border: $warnMessageBorder;
                 color: $warnMessageTextColor;

                 .p-toast-close-icon {
                     color: $warnMessageTextColor;
                 }

                 .p-toast-icon {
                     color: darken($warnMessageBgColor, 30%);
                 }
             }

             &.p-toast-message-error {
                 background-color: $errorMessageBgColor;
                 border: $errorMessageBorder;
                 color: $errorMessageTextColor;

                 .p-toast-close-icon {
                     color: $errorMessageTextColor;
                 }

                 .p-toast-icon {
                     color: darken($errorMessageBgColor, 30%);
                 }
             }
         }
     }

}
