body {
    .p-breadcrumb {
        background-color: $panelContentBgColor;
        border: $panelContentBorder;
        padding: $panelContentPadding;

        ul {
            li {
                float: none;
                display: inline-block;
                vertical-align: middle;

                .p-menuitem-link {
                    color: $panelContentTextColor;
                    margin: 0;
                    float: none;
                }

                &.p-breadcrumb-chevron {
                    margin: 0 $inlineSpacing 0 $inlineSpacing;
                    color: $textSecondaryColor;
                }

                &:first-child {
                    a {
                        color: $textSecondaryColor;
                        margin: 0;
                        font-size: $fontSize + 4;
                    }
                }
            }
        }
    }

    .p-steps {
        position: relative;

        .p-steps-item {
            background-color: transparent;
            text-align: center;

            .p-menuitem-link {
                display: inline-block;
                text-align: center;
                background-color: transparent;
                overflow: hidden;

                .p-steps-number {
                    @include border-radius(50%);
                    display: inline-block;
                    color: $stepsItemNumberColor;
                    background-color: $stepsItemBgColor;
                    border: $stepsItemBorder;
                    position: relative;
                    top: $fontSize;
                    margin-bottom: $fontSize;
                    width: $stepsItemWidth;
                    height: $stepsItemHeight;
                    font-size: $fontSize + 2;
                    line-height: $stepsItemHeight - 4;
                    text-align: center;
                    padding-top: .25em;
                }

                .p-steps-title {
                    display: block;
                    margin-top: 6px;
                    color: $stepsItemTextColor;
                }
            }

            &.p-state-highlight {
                .p-steps-number {
                    background: $highlightBgColor;
                    color: $highlightColorText;
                }

                .p-steps-title {
                    font-weight: 700;
                    color: $textColor;
                }
            }

            &:last-child {
                .p-menuitem-link {
                    display: block;
                }
            }
        }

        &:before {
            content:' ';
            border-top: $panelContentBorder;
            width: 100%;
            top: 45%;
            left: 0;
            display: block;
            position: absolute;
        }
    }

    .p-menu .p-menuitem-link,
    .p-menubar .p-menuitem-link,
    .p-tieredmenu .p-menuitem-link,
    .p-contextmenu .p-menuitem-link,
    .p-megamenu .p-menuitem-link,
    .p-slidemenu .p-menuitem-link {
        padding: $menuitemPadding;
        color: $menuitemTextColor;
        font-weight: normal;
        @include border-radius(0);

        .p-menuitem-text {
            color: $menuitemTextColor;
        }

        .p-menuitem-icon {
            color: $menuitemIconColor;
        }

        &:hover {
            background-color: $menuitemHoverBgColor;

            .p-menuitem-text {
                color: $menuitemHoverTextColor;
            }

            .p-menuitem-icon {
                color: $menuitemHoverIconColor;
            }
        }
    }

    .p-menu {
        padding: $menuPadding;
        background-color: $menuBgColor;
        border: $menuBorder;

        .p-menuitem {
            margin: $menuitemMargin;
        }

        &.p-shadow {
            border: $overlayMenuBorder;
            @include shadow($overlayMenuShadow);
        }

        .p-submenu-header {
            margin: $submenuHeaderMargin;
            padding: $panelHeaderPadding;
            color: $panelHeaderTextColor;
            background-color: $panelHeaderBgColor;
            font-weight: $panelHeaderFontWeight;
            border: 0 none;
        }
    }

    .p-menubar {
        padding: $menuPadding;
        background-color: $menuBgColor;
        border: $menuBorder;

        .p-menubar-root-list {
            > .p-menuitem {
                > .p-menuitem-link {
                    padding: $menuitemPadding;
                }
            }
        }

        .p-submenu-list {
            padding: $submenuPadding;
            background-color: $menuBgColor;
            border: $overlayMenuBorder;
            @include shadow($overlayMenuShadow);
        }

        .p-menuitem {
            margin: $menuitemMargin;

            .p-menuitem-link {
                .p-submenu-icon {
                    vertical-align: middle;
                    right: 0.429em;
                }
            }

            &.p-menuitem-active {
                > .p-menuitem-link {
                    background-color: $menuitemActiveBgColor;

                    .p-menuitem-text {
                        color: $menuitemActiveTextColor;
                    }

                    .p-menuitem-icon {
                        color: $menuitemActiveIconColor;
                    }
                }
            }
        }
    }

    .p-contextmenu {
        padding: $menuPadding;
        background-color: $menuBgColor;
        border: $overlayMenuBorder;
        @include shadow($overlayMenuShadow);

        .p-submenu-list {
            padding: $submenuPadding;
            background-color: $menuBgColor;
            border: $overlayMenuBorder;
            @include shadow($overlayMenuShadow);
        }

        .p-menuitem {
            margin: $menuitemMargin;

            .p-menuitem-link {
                .p-submenu-icon {
                    right: 0.429em;
                }
            }

            &.p-menuitem-active {
                > .p-menuitem-link {
                    background-color: $menuitemActiveBgColor;

                    .p-menuitem-text {
                        color: $menuitemActiveTextColor;
                    }

                    .p-menuitem-icon {
                        color: $menuitemActiveIconColor;
                    }
                }
            }
        }
    }

    .p-tieredmenu {
        padding: $menuPadding;
        background-color: $menuBgColor;
        border: $menuBorder;

        .p-submenu-list {
            padding: $submenuPadding;
            background-color: $menuBgColor;
            border: $overlayMenuBorder;
            @include shadow($overlayMenuShadow);
        }

        .p-menuitem {
            margin: $menuitemMargin;

            .p-menuitem-link {
                .p-submenu-icon {
                    right: 0.429em;
                }
            }

            &.p-menuitem-active {
                > .p-menuitem-link {
                    background-color: $menuitemActiveBgColor;

                    .p-menuitem-text {
                        color: $menuitemActiveTextColor;
                    }

                    .p-menuitem-icon {
                        color: $menuitemActiveIconColor;
                    }
                }
            }
        }
    }

    .p-slidemenu {
        padding: $menuPadding;
        background-color: $menuBgColor;
        border: $menuBorder;

        .p-submenu-list {
            padding: $submenuPadding;
            background-color: $menuBgColor;
            border: 0 none;
        }

        .p-menuitem {
            margin: $menuitemMargin;

            .p-menuitem-link {
                .p-submenu-icon {
                    right: 0.429em;
                }
            }

            &.p-menuitem-active {
                > .p-menuitem-link {
                    background-color: $menuitemActiveBgColor;

                    .p-menuitem-text {
                        color: $menuitemActiveTextColor;
                    }

                    .p-menuitem-icon {
                        color: $menuitemActiveIconColor;
                    }
                }
            }
        }

        .p-slidemenu-backward {
            margin: $submenuHeaderMargin;
            padding: $panelHeaderPadding;
            color: $panelHeaderTextColor;
            background-color: $panelHeaderBgColor;
            font-weight: $panelHeaderFontWeight;
            border: 0 none;

            &:hover {
                background-color: $panelHeaderHoverBgColor;
                color: $panelHeaderTextColor;
            }
        }
    }

    .p-tabmenu {
        border: 0 none;
        margin-bottom: 1rem;

        .p-tabmenu-nav {
            padding: 0;
            background: $tabsNavBgColor;
            border-bottom: $panelHeaderBorder;

            .p-tabmenuitem {
                position: static;
                border: none;
                margin-bottom: 0;
                background-color: $panelHeaderBgColor;
                margin-right: $tabHeaderSpacing;

                .p-menuitem-link {
                    color: $panelHeaderTextColor;
                    padding: $panelHeaderPadding;
                    font-weight: 500;
                    margin-bottom: -1px;

                    .p-menuitem-text {
                        color: $panelHeaderTextColor;
                        margin-right: $inlineSpacing;
                    }

                    .p-menuitem-icon {
                        padding-bottom: .5rem;

                        &::before {
                            color: $panelHeaderIconColor;
                            font-size: 1.5rem;
                        }
                    }
                }

                &:not([aria-selected="true"]):not(.p-state-disabled):hover {
                    background-color: $panelHeaderHoverBgColor;
                    //border: $panelHeaderHoverBorder;

                    .p-menuitem-link {
                        .p-menuitem-text {
                            color: $panelHeaderHoverTextColor;
                        }

                        .p-menuitem-icon {
                            color: $panelHeaderIconHoverColor;
                        }
                    }
                }

                &.p-highlight[aria-selected="true"] {
                    .p-menuitem-link {
                        background-color: $panelHeaderActiveBgColor;
                        border: $panelHeaderActiveBorder;

                        .p-menuitem-text {
                            color: $panelHeaderActiveTextColor;
                        }

                        .p-menuitem-icon::before {
                            color: $panelHeaderActiveIconColor;
                        }
                    }
                }
            }
        }
    }

    .p-megamenu {
        padding: $menuPadding;
        background-color: $menuBgColor;
        border: $menuBorder;

        .p-megamenu-submenu-header {
            margin: $submenuHeaderMargin;
            padding: $panelHeaderPadding;
            color: $panelHeaderTextColor;
            background-color: $panelHeaderBgColor;
            font-weight: $panelHeaderFontWeight;
            border: 0 none;
        }

        .p-megamenu-panel {
            padding: $submenuPadding;
            background-color: $menuBgColor;
            border: $overlayMenuBorder;
            @include shadow($overlayMenuShadow);
        }

        .p-menuitem {
            margin: $menuitemMargin;

            .p-menuitem-link {
                .p-submenu-icon {
                    right: 0.429em;
                }
            }

            &.p-menuitem-active {
                > .p-menuitem-link {
                    background-color: $menuitemActiveBgColor;

                    .p-menuitem-text {
                        color: $menuitemActiveTextColor;
                    }

                    .p-menuitem-icon {
                        color: $menuitemActiveIconColor;
                    }
                }
            }
        }

        &.p-megamenu-vertical {
            .p-megamenu-root-list {
                > .p-menuitem {
                    > .p-menuitem-link {
                        > .p-submenu-icon {
                            vertical-align: middle;
                            right: 0.429em;
                        }
                    }
                }
            }
        }
    }

    .p-panelmenu {
        .p-icon {
            position: static;
        }

        .p-panelmenu-header {
            padding: 0;

            > a {
                border: $panelHeaderBorder;
                background-color: $panelHeaderBgColor;
                color: $panelHeaderTextColor;
                padding: $panelHeaderPadding;
                font-weight: $panelHeaderFontWeight;
                position: static;
                font-size: $fontSize;

                .p-panelmenu-icon {
                    color: $panelHeaderIconColor;
                    position: static;
                    display: inline-block;
                    margin-right: $inlineSpacing;
                }
            }

            &:not(.p-state-active) {
                > a:hover {
                    outline: 0 none;
                    border: $panelHeaderHoverBorder;
                    background-color: $panelHeaderHoverBgColor;
                    color: $panelHeaderHoverTextColor;

                    .p-panelmenu-icon {
                        color: $panelHeaderHoverIconColor;
                    }
                }
            }

            &.p-state-active {
                > a {
                    border: $panelHeaderActiveBorder;
                    background-color: $panelHeaderActiveBgColor;
                    color: $panelHeaderActiveTextColor;

                    .p-panelmenu-icon {
                        color: $panelHeaderActiveIconColor;
                    }

                    &:hover {
                        outline: 0 none;
                        border: $panelHeaderActiveHoverBorder;
                        background-color: $panelHeaderActiveHoverBgColor;
                        color: $panelHeaderActiveHoverTextColor;

                        .p-panelmenu-icon {
                            color: $panelHeaderActiveHoverIconColor;
                        }
                    }
                }
            }
        }

        .p-panelmenu-panel {
            margin-top: $accordionSpacing;

            &:first-child {
                margin-top: 0;
            }
        }

        .p-panelmenu-content {
            padding: $menuPadding;
            background-color: $menuBgColor;
            border: $menuBorder;
            margin-top: 0;
            position: static;
            border-top: 0 none;

            .p-menuitem {
                margin: $menuitemMargin;

                .p-menuitem-link {
                    padding: $menuitemPadding;
                    color: $menuitemTextColor;

                    &:hover {
                        background-color: $menuitemHoverBgColor;

                        .p-menuitem-icon, .p-panelmenu-icon {
                            color: $menuitemHoverIconColor;
                        }

                        .p-menuitem-text {
                            color: $menuitemHoverTextColor;
                        }
                    }
                }
            }
        }
    }
}
