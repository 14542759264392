* {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

html {
    height: 100%;
}

body {
    font-family: $fontFamily;
    font-size: $fontSize;
    font-weight: 500;
    color: $textColor;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: 0;
    margin: 0;
    min-height: 100%;
    background-color: $bodyBgColor;
}

a {
    text-decoration: none;
}
