@import '../_variables';
@import '../overrides/theme_variables';

//anchor
$linkColor:$primaryColor;
$linkHoverColor:$primaryDarkerColor;
$linkActiveColor:$primaryDarkestColor;

//highlight
$highlightBgColor:$primaryColor;
$highlightColorText:$primaryTextColor;

//input field
$inputPadding:.429em;
$inputBgColor:#ffffff;
$inputBorder:1px solid #c4c4c4;
$inputBorderRadius: 0px;
$inputHoverBorderColor:#7f7f7f;
$inputFocusBorderColor:#7f7f7f;
$inputErrorBorderColor:#a80000;
$inputPlaceholderTextColor:#666666;
$inputFocusShadow:0 0 6px 0 rgba(0, 0, 0, 0.16);
$inputTransition:border-color $transitionDuration,box-shadow $transitionDuration;

//groups
$inputGroupBorderColor:#c4c4c4;
$inputGroupBgColor:#f4f4f4;
$inputGroupTextColor:$textColor;

//input lists
$inputListMinWidth:12em;
$inputListBgColor:#ffffff;
$inputListPadding:0;

$inputListItemPadding:0.429em 0.857em;
$inputListItemBgColor:transparent;
$inputListItemTextColor:#333333;
$inputListItemHoverBgColor:#eaeaea;
$inputListItemHoverTextColor:#333333;
$inputListItemHighlightBgColor:$highlightBgColor;
$inputListItemHighlightTextColor:$highlightColorText;
$inputListItemBorder:0 none;
$inputListItemMargin:0;
$inputListItemDividerColor:#d8dae2;

$inputListHeaderPaddingTop:0.429em;
$inputListHeaderPaddingLeft:0.857em;
$inputListHeaderPaddingRight:0.857em;
$inputListHeaderPaddingBottom:0.429em;
$inputListHeaderBgColor:#ffffff;
$inputListHeaderTextColor:$textColor;
$inputListHeaderBorder:1px solid #eaeaea;
$inputListHeaderMargin:0;

$inputListHeaderSearchIconColor:$primaryColor;
$inputListHeaderCloseIconColor:$textColor;
$inputListHeaderCloseIconHoverColor:$primaryColor;
$inputListHeaderCloseIconTransition:color $transitionDuration;

//inputs with panels (e.g. password)
$inputContentPanelPadding:.857em;
$inputContentPanelBgColor:#ffffff;
$inputContentPanelTextColor:$textColor;

//inputs with overlays
$inputOverlayBorder:1px solid #dedede;
$inputOverlayShadow:0 2px 4px 0 rgba(0, 0, 0, 0.10);

//input dropdowns
$inputDropdownIconColor:$textColor;

//button
$buttonTextOnlyPadding:0.429em 1em;
$buttonWithLeftIconPadding:0.429em 1em 0.429em 2em;
$buttonWithRightIconPadding:0.429em 2em 0.429em 1em;
$buttonIconOnlyPadding:0.429em;
$buttonIconOnlyWidth:2.143em;

$buttonBgColor:$primaryColor;
$buttonBorder:1px solid $primaryColor;
$buttonTextColor:$primaryTextColor;

$buttonHoverBgColor:$primaryDarkerColor;
$buttonHoverTextColor:$primaryTextColor;
$buttonHoverBorderColor:$primaryDarkerColor;

$buttonActiveBgColor:$primaryDarkestColor;
$buttonActiveTextColor:$primaryTextColor;
$buttonActiveBorderColor:$primaryDarkestColor;

$buttonFocusOutline:1px solid $primaryColor;
$buttonFocusOutlineOffset:1px;
$buttonFocusBorderColor:$primaryColor;
$buttonTransition:background-color $transitionDuration;
$raisedButtonShadow:0 2px 4px 0 rgba(0, 0, 0, 0.10);
$roundedButtonBorderRadius:1em;

$secondaryButtonBgColor:#f3f3f3;
$secondaryButtonBorder:1px solid #f3f3f3;
$secondaryButtonTextColor:#333333;
$secondaryButtonHoverBgColor:#d1d1d1;
$secondaryButtonHoverTextColor:#212121;
$secondaryButtonHoverBorderColor:#d1d1d1;
$secondaryButtonActiveBgColor:$primaryColor;
$secondaryButtonActiveTextColor:#ffffff;
$secondaryButtonActiveBorderColor:$primaryColor;

$warningButtonBgColor:#ffba01;
$warningButtonTextColor:#333333;
$warningButtonBorder:1px solid #ffba01;
$warningButtonHoverBgColor:#ED990B;
$warningButtonHoverTextColor:#333333;
$warningButtonHoverBorderColor:#ED990B;
$warningButtonActiveBgColor:#D38B10;
$warningButtonActiveTextColor:#333333;
$warningButtonActiveBorderColor:#D38B10;

$successButtonBgColor:#34A835;
$successButtonTextColor:#ffffff;
$successButtonBorder:1px solid #34A835;
$successButtonHoverBgColor:#107D11;
$successButtonHoverTextColor:#ffffff;
$successButtonHoverBorderColor:#107D11;
$successButtonActiveBgColor:#0C6B0D;
$successButtonActiveTextColor:#ffffff;
$successButtonActiveBorderColor:#0C6B0D;

$infoButtonBgColor:#007ad9;
$infoButtonTextColor:#ffffff;
$infoButtonBorder:1px solid #007ad9;
$infoButtonHoverBgColor:#116fbf;
$infoButtonHoverTextColor:#ffffff;
$infoButtonHoverBorderColor:#116fbf;
$infoButtonActiveBgColor:#005b9f;
$infoButtonActiveTextColor:#ffffff;
$infoButtonActiveBorderColor:#005b9f;

$dangerButtonBgColor:#e91224;
$dangerButtonTextColor:#ffffff;
$dangerButtonBorder:1px solid #e91224;
$dangerButtonHoverBgColor:#c01120;
$dangerButtonHoverTextColor:#ffffff;
$dangerButtonHoverBorderColor:#c01120;
$dangerButtonActiveBgColor:#a90000;
$dangerButtonActiveTextColor:#ffffff;
$dangerButtonActiveBorderColor:#a90000;

//checkbox
$checkboxWidth:29px;
$checkboxHeight:29px;
$checkboxActiveBorderColor:$primaryColor;
$checkboxActiveBgColor:$primaryColor;
$checkboxActiveHoverBgColor:$primaryDarkestColor;
$checkboxActiveTextColor:$primaryTextColor;
$checkboxActiveHoverTextColor:$primaryTextColor;
$checkboxActiveHoverBorderColor:$primaryDarkestColor;
$checkboxActiveFocusBgColor:$primaryDarkerColor;
$checkboxActiveFocusTextColor:$primaryTextColor;
$checkboxActiveFocusBorderColor:$primaryDarkerColor;
$checkboxFocusBgColor:$inputBgColor;
$checkboxFocusTextColor:$primaryColor;
$checkboxFocusBorderColor:$primaryColor;
$checkboxTransition:background-color $transitionDuration, border-color $transitionDuration;

//radiobutton
$radiobuttonWidth:20px;
$radiobuttonHeight:20px;
$radiobuttonActiveBorderColor:$primaryColor;
$radiobuttonActiveBgColor:$primaryColor;
$radiobuttonActiveTextColor:$primaryTextColor;
$radiobuttonActiveHoverBgColor:$primaryDarkestColor;
$radiobuttonActiveHoverTextColor:$primaryTextColor;
$radiobuttonActiveHoverBorderColor:$primaryDarkestColor;
$radiobuttonActiveFocusBgColor:$primaryDarkerColor;
$radiobuttonActiveFocusTextColor:$primaryTextColor;
$radiobuttonActiveFocusBorderColor:$primaryDarkerColor;
$radiobuttonFocusBgColor:$inputBgColor;
$radiobuttonFocusTextColor:$primaryColor;
$radiobuttonFocusBorderColor:$primaryColor;
$radiobuttonTransition:background-color $transitionDuration, border-color $transitionDuration;

//togglebutton
$toggleButtonBgColor:#d1d1d1;
$toggleButtonBorder:1px solid #d1d1d1;
$toggleButtonTextColor:#333333;
$toggleButtonIconColor:#666666;
$toggleButtonHoverBgColor:#c8c8c8;
$toggleButtonHoverBorderColor:#c8c8c8;
$toggleButtonHoverTextColor:#333333;
$toggleButtonHoverIconColor:#212121;
$toggleButtonActiveBgColor:$primaryColor;
$toggleButtonActiveBorderColor:$primaryColor;
$toggleButtonActiveTextColor:$primaryTextColor;
$toggleButtonActiveIconColor:$primaryTextColor;
$toggleButtonActiveHoverBgColor:$primaryDarkestColor;
$toggleButtonActiveHoverBorderColor:$primaryDarkestColor;
$toggleButtonActiveHoverTextColor:$primaryTextColor;
$toggleButtonActiveHoverIconColor:$primaryTextColor;
$toggleButtonFocusOutline:0 none;
$toggleButtonFocusBgColor:#c8c8c8;
$toggleButtonFocusBorderColor:#7f7f7f;
$toggleButtonFocusTextColor:#333333;
$toggleButtonFocusIconColor:#212121;
$toggleButtonActiveFocusBgColor:$primaryColor;
$toggleButtonActiveFocusBorderColor:#7f7f7f;
$toggleButtonActiveFocusTextColor:$primaryTextColor;
$toggleButtonActiveFocusIconColor:$primaryTextColor;

//inplace
$inplacePadding:$inputPadding;
$inplaceHoverBgColor:#eaeaea;
$inplaceHoverTextColor:$textColor;
$inplaceTransition:background-color $transitionDuration;

//rating
$ratingTransition:color $transitionDuration;
$ratingCancelIconColor:#e4018d;
$ratingCancelHoverIconColor:#b5019f;
$ratingIconFontSize:1.429em;
$ratingStarIconColor:$textColor;
$ratingStarIconHoverColor:$primaryDarkestColor;
$ratingStarActiveIconColor:$primaryColor;

//slider
$sliderBgColor:#c4c4c4;
$sliderBorder:0 none;
$sliderHeight:.286em;
$sliderWidth:.286em;
$sliderHandleWidth:1.429em;
$sliderHandleHeight:1.429em;
$sliderHandleBgColor:#ffffff;
$sliderHandleBorder:2px solid #7f7f7f;
$sliderHandleHoverBorder:2px solid $primaryColor;
$sliderHandleHoverBgColor:2px solid #7f7f7f;
$sliderHandleBorderRadius:50%;
$sliderHandleTransition:background-color $transitionDuration;
$sliderRangeBgColor:$primaryColor;

//calendar
$calendarWidth:18.571em;
$calendarNavIconColor:#3F4040;
$calendarNavIconHoverColor:$primaryColor;
$calendarNavIconTransition:color $transitionDuration;
$calendarPadding:0.857em;
$calendarTableMargin:0.857em 0 0 0;
$calendarCellPadding:0.286em;
$calendarCellDatePadding:0.286em;
$calendarCellDateWidth:1.714em;
$calendarCellDateHeight:1.714em;
$calendarCellDateHoverBgColor:#efefef;
$calendarCellDateBorderRadius:$borderRadius;
$calendarCellDateSelectedBgColor:$primaryColor;
$calendarCellDateSelectedTextColor:#ffffff;
$calendarCellDateTodayBgColor:#ffffff;
$calendarCellDateTodayTextColor:$textColor;

$calendarTimePickerDivider: 1px solid #d8dae2;
$calendarTimePickerPadding:0.857em;
$calendarTimePickerIconColor:$textColor;
$calendarTimePickerIconFontSize:1.286em;
$calendarTimePickerTimeFontSize:1.286em;
$calendarTimePickerIconHoverColor:$primaryColor;

//spinner
$spinnerButtonWidth:1.429em;

//input switch
$inputSwitchBorderRadius:8px;
$inputSwitchOffHandleBgColor:darken($inputBgColor, 10%);
$inputSwitchOffHandleHoverBgColor:#848484;
$inputSwitchOffHandleFocusBgColor:#848484;
$inputSwitchOnBorderColor:lighten($highlightBgColor, 15%);
$inputSwitchOnBgColor:lighten($highlightBgColor, 15%);
$inputSwitchOnHandleBgColor:lighten($highlightBgColor, 5%);
$inputSwitchOnHandleFocusBgColor:$primaryDarkerColor;
$inputSwitchOnHandleHoverBgColor:$primaryDarkestColor;
$inputSwitchTransition:border-color $transitionDuration;
$inputSwitchHandleTransition:background-color $transitionDuration;

//panel common
$panelHeaderBorder:$containerHeaderBorder;
$panelHeaderBgColor:$containerHeaderBgColor;
$panelHeaderTextColor:$containerHeaderTextColor;
$panelHeaderIconColor:$containerHeaderIconColor;
$panelHeaderIconHoverColor:$containerHeaderIconHoverColor;
$panelHeaderIconTransition:color $transitionDuration;
$panelHeaderFontWeight:500;
$panelHeaderPadding:.857em 1em;
$panelContentBorder:1px solid #dedede;
$panelContentBgColor:#ffffff;
$panelContentTextColor:$textColor;
$panelContentPadding:.571em 1em;
$panelFooterBorder:1px solid #dedede;
$panelFooterBgColor:#ffffff;
$panelFooterTextColor:$textColor;
$panelFooterPadding:.571em 1em;
$panelHeaderHoverBgColor:$containerHeaderHoverBgColor;
$panelHeaderHoverBorder:$containerHeaderHoverBorder;
$panelHeaderHoverTextColor:$containerHeaderHoverTextColor;
$panelHeaderHoverIconColor:$containerHeaderHoverIconColor;
$panelHeaderActiveBgColor:$blue-moderate;
$panelHeaderActiveBorder:1px solid $blue-moderate;
$panelHeaderActiveTextColor:$primaryTextColor;
$panelHeaderActiveIconColor:$primaryTextColor;
$panelHeaderActiveHoverBgColor:$containerHeaderActiveHoverBgColor;
$panelHeaderActiveHoverBorder:$containerHeaderActiveHoverHoverBorder;
$panelHeaderActiveHoverTextColor:$containerHeaderActiveHoverrTextColor;
$panelHeaderActiveHoverIconColor:$containerHeaderActiveHoverIconColor;

//accordion
$accordionSpacing:2px;

//tabview
$tabHeaderSpacing:.214em;
$tabsNavBorder:0 none;
$tabsNavBgColor:transparent;

//scrollpanel
$scrollPanelHandleBgColor:#dadada;
$scrollPanelTrackBorder:0 none;
$scrollPanelTrackBgColor:#f8f8f8;

//paginator
$paginatorBgColor:#ffffff;
$paginatorBorder:1px solid #dedede;
$paginatorIconColor:$textSecondaryColor;
$paginatorPadding:0;
$paginatorElementPadding:0;
$paginatorElementWidth:2.308em;
$paginatorElementHeight:2.308em;
$paginatorElementHoverBgColor:#eaeaea;
$paginatorElementHoverIconColor:#333333;
$paginatorElementMargin:0;
$paginatorElementBorder:0 none;
$paginatorElementBorderRadius:0;

//table
$tableHeaderCellPadding:.500em 1.214em;
$tableHeaderCellBgColor:#ffffff;
$tableHeaderCellTextColor:$textColor;
$tableHeaderCellFontWeight:700;
$tableHeaderCellBorder:1px solid #dedede;
$tableHeaderCellHoverBgColor:#e0e0e0;
$tableHeaderCellHoverTextColor:#333333;
$tableBodyRowBgColor:#ffffff;
$tableBodyRowTextColor:$textColor;
$tableBodyRowEvenBgColor:#f8f8f8;
$tableBodyRowHoverBgColor:#e0e0e0;
$tableBodyRowHoverTextColor:#333333;
$tableBodyCellPadding:.500em 1.214em;
$tableBodyCellBorder:1px solid #dedede;
$tableFooterCellPadding:.500em 1.214em;
$tableFooterCellBgColor:#ffffff;
$tableFooterCellTextColor:$textColor;
$tableFooterCellFontWeight:500;
$tableFooterCellBorder:1px solid #dedede;
$tableResizerHelperBgColor:$primaryColor;

//schedule
$scheduleEventBgColor:$primaryDarkestColor;
$scheduleEventBorder:1px solid $primaryDarkestColor;
$scheduleEventTextColor:#ffffff;

//tree
$treeNodePadding:0.143em 0;
$treeNodeLabelPadding:0.286em;
$treeNodeContentSpacing:0.143em;

//lightbox
$lightBoxNavIconFontSize:3em;
$lightBoxNavIconColor:#ffffff;

//org chart
$organizationChartConnectorColor:#c8c8c8;

//messages
$messagesMargin:0em 0;
$messagesPadding:0em;
$messagesIconFontSize:1.714em;
$messageCloseIconFontSize:1.5em;

$infoMessageBgColor:#F9FCFF;
$infoMessageBorder:1px solid #82C2FF;
$infoMessageTextColor:#071A38;
$infoMessageIconColor:#071A38;

$successMessageBgColor:#b7d8b7;
$successMessageBorder:0 none;
$successMessageTextColor:#212121;
$successMessageIconColor:#212121;

$warnMessageBgColor:#FFF0D2;
$warnMessageBorder:1px solid #FFD66B;
$warnMessageTextColor:#4D3B0A;
$warnMessageIconColor:#4D3B0A;

$errorMessageBgColor:#FF8474;
$errorMessageBorder:1px solid #FF3318;
$errorMessageTextColor:#370909;
$errorMessageIconColor:#370909;

//growl
$growlTopLocation:120px;
$growlIconFontSize:3.429em;
$growlMessageTextMargin: 0 0 0 4em;
$growlMargin:0 0 1em 0;
$growlPadding:1em;
$growlShadow:0 3px .429em 0 rgba(0, 0, 0, 0.16);
$growlOpacity:.9;

//message
$messagePadding:$inputPadding;
$messageMargin:0;

//overlays
$overlayContentBorder:none;
$overlayContentBorderColor:#dedede;
$overlayContainerShadow:0 0 .429em 0 rgba(0, 0, 0, 0.16);

//overlay panel
$overlayPanelCloseIconBgColor:$primaryColor;
$overlayPanelCloseIconColor:#ffffff;
$overlayPanelCloseIconHoverBgColor:$primaryDarkestColor;
$overlayPanelCloseIconHoverColor:#ffffff;

//tooltip
$tooltipBgColor:#333333;
$tooltipTextColor:#ffffff;
$tooltipPadding:$inputPadding;

//steps
$stepsItemBgColor:#ffffff;
$stepsItemBorder:1px solid #dedede;
$stepsItemNumberColor:$textColor;
$stepsItemTextColor:$textSecondaryColor;
$stepsItemWidth:2em;
$stepsItemHeight:2em;

//progressbar
$progressBarHeight:1.714em;
$progressBarBorder:0 none;
$progressBarBgColor:#efefef;
$progressBarValueBgColor:$primaryColor;

//menu
$menuBgColor:#ffffff;
$menuBorder:1px solid #dedede;
$menuPadding:0;
$menuTextColor:$textColor;
$menuitemPadding:.571em .857em;
$menuitemMargin:0;
$menuitemTextColor:#333333;
$menuitemIconColor:#333333;
$menuitemHoverTextColor:#333333;
$menuitemHoverIconColor:#333333;
$menuitemHoverBgColor:#eaeaea;
$menuitemActiveTextColor:#333333;
$menuitemActiveIconColor:#333333;
$menuitemActiveBgColor:#eaeaea;
$submenuHeaderMargin: 0;
$submenuPadding: 0;
$overlayMenuBorder:1px solid #dedede;
$overlayMenuShadow:0 2px 4px 0 rgba(0, 0, 0, 0.10);

//misc
$maskBgColor:rgba(0, 0, 0, 0.4);
$inlineSpacing:.429em;
$chipsItemMargin:0 .286em 0 0;
$dataIconColor:$textSecondaryColor;
$dataIconHoverColor:$textColor;

//dialog
$dialogHeaderPadding:1em;
$confirmDialogPadding:1.5em;

//general
$disabledOpacity:.5;

//carousel
$carouselNavButtonsBgColor: $buttonBgColor;
$carouselNavButtonsBorder: $buttonBorder;
$carouselNavButtonsBorderRadius: 0;
$carouselNavButtonsMargin: .2em;
$carouselNavButtonsColor: $buttonTextColor;
$carouselNavButtonsHoverBgColor: $buttonHoverBgColor;
$carouselNavButtonsHoverColor: $buttonHoverTextColor;
$carouselNavButtonsHoverBorderColor: $buttonHoverBorderColor;
$carouselNavButtonsTransition: color $transitionDuration;
$carouselDotIconWidth: 20px;
$carouselDotIconHeight: 6px;
$carouselDotIconBgColor: #b2c1cd;
$carouselDotIconMargin: 0 .2em;
$carouselActiveDotIconBgColor: $primaryColor;
