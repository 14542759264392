/* Add your variable customizations of theme here */

$propakRed: #e8112d;

// theme colors

$white: #ffffff;

$red-light: #F98998;
$red-moderate: $propakRed;
$red-dark: #BE041E;
$red-darker: #9F0016;

$blue-light: #A9C8E8;
$blue-moderate: #3269A2;
$blue-dark: #15629D;
$blue-darker: #07396C;

$green-lighter: #BEF5AC;
$green-light: #9BEB82;
$green-moderate: #3DCF0F;
$green-dark: #2CAA04;
$green-darker: #228D00;

$yellow-light: #FFD18D;
$yellow-moderate: #F49912;
$yellow-dark: #C87A04;
$yellow-darker: #A76400;

$gray-7: #f3f3f3;
$gray-6: #d1d1d1;
$gray-5: #c8c8c8;
$gray-4: #7f7f7f;
$gray-3: #666666;
$gray-2: #333333;
$gray-1: #212121;
